
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    
import useTranslation from 'next-translate/useTranslation';

import BlockIntro from 'components/block-intro';
import ROUTE from 'config/routes';

const NotFoundPage = () => {
  const { t } = useTranslation('common');

  return (
    <div className="not-found-page">
      <div className="u-pattern" />

      <div className="u-container">
        <BlockIntro
          title={t('NOT_FOUND.TITLE')}
          descr={t('NOT_FOUND.DESCR')}
          level={1}
          cta={{
            text: t('NOT_FOUND.CTA'),
            href: ROUTE.home,
          }}
        />
      </div>
    </div>
  );
};

export default NotFoundPage;


    export async function getStaticProps(ctx) {
        
        
        return {
          
          
          props: {
            
            ...(await __loadNamespaces({
              ...ctx,
              pathname: '/404',
              loaderName: 'getStaticProps',
              ...__i18nConfig,
              loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
            }))
          }
        }
    }
  